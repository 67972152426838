import { createSlice } from "@reduxjs/toolkit";

export const inviteSlice = createSlice({
    name: 'claim',
    initialState: {
        invites: [],
        leagues: []
    },
    reducers: {
        setClaimInvites: (state, action) => {
            return {
                ...state,
                invites: action.payload
            }
        },
        setClaimLeagues: (state, action) => {
            return {
                ...state,
                leagues: action.payload
            }
        },
    }
});
export const { setClaimInvites, setClaimLeagues } = inviteSlice.actions;
export default inviteSlice.reducer