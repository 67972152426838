import { createSlice } from "@reduxjs/toolkit";

export const securitySlice = createSlice({
    name: 'security',
    initialState: {
        jwt: ''
    },
    reducers: {
        setJWT: (state, action) => {
            return {
                jwt: action.payload
            };
        }
    }
});
export const { setJWT } = securitySlice.actions;
export default securitySlice.reducer