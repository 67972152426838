function loopClamp(min: number, max: number, prev: number, current: number) {
    // Calculate the range
    const range = max - min + 1;

    // Determine the new value based on direction
    let newValue;
    if (current > max) {
        // Wrap around to min
        newValue = min + (current - max - 1) % range;
    } else if (current < min) {
        // Wrap around to max
        newValue = max - (min - current - 1) % range;
    } else {
        newValue = current;
    }

    return newValue;
}
function clamp(min: number, max: number, value: number): number {


    return Math.max(min, Math.min(max, value));
}
function toNumberFormat(number: number) {
    return new Intl.NumberFormat().format(number);
}
type KeyFunction<T> = (item: T) => string | number;
function groupBy<T>(array: T[], keyFn: KeyFunction<T>): { [key: string]: T[] } {
    return array.reduce((result, item) => {
        const key = keyFn(item);
        if (!result[key]) {
            result[key] = [];
        }
        result[key].push(item);
        return result;
    }, {} as { [key: string]: T[] });
}

/**
 * Intersect two arrays of objects by specific keys.
 * @param array1 - The first array of objects.
 * @param array2 - The second array of objects.
 * @param keyExtractor1 - Function to extract the key from objects in array1.
 * @param keyExtractor2 - Function to extract the key from objects in array2.
 * @returns The array of intersected objects from the first array.
 */
function intersectByKeys<T1, T2>(
    array1: T1[],
    array2: T2[],
    keyExtractor1: (item: T1) => any,
    keyExtractor2: (item: T2) => any
): T1[] {
    const set2 = new Set(array2.map(keyExtractor2));
    return array1.filter(item => set2.has(keyExtractor1(item)));
}

function deepClone<T>(object: T): T {
    return JSON.parse(JSON.stringify(object))
}

type KeyFunc<T> = (obj: T) => number;

function min<T>(arr: T[], key: keyof T | KeyFunc<T>): T | null {
    if (arr.length === 0) return null;

    return arr.reduce((minObj, currentObj) => {
        const currentKeyValue = typeof key === 'function' ? key(currentObj) : currentObj[key];
        const minKeyValue = typeof key === 'function' ? key(minObj) : minObj[key];
        return (currentKeyValue as any) < (minKeyValue as any) ? currentObj : minObj;
    });
}

function max<T>(arr: T[], key: keyof T | KeyFunc<T>): T | null {
    if (arr.length === 0) return null;

    return arr.reduce((maxObj, currentObj) => {
        const currentKeyValue = typeof key === 'function' ? key(currentObj) : currentObj[key];
        const maxKeyValue = typeof key === 'function' ? key(maxObj) : maxObj[key];
        return (currentKeyValue as any) > (maxKeyValue as any) ? currentObj : maxObj;
    });
}
function normalizeValue(value: number, minValue: number, maxValue: number, minScale: number, maxScale: number): number {
    // Prevent division by zero if minValue equals maxValue
    if (minValue === maxValue) {
        return minScale;
    }
    return ((value - minValue) / (maxValue - minValue)) * (maxScale - minScale) + minScale;
}
export { loopClamp, clamp, toNumberFormat, groupBy, intersectByKeys, deepClone, min, max, normalizeValue }