import millify from 'millify';
import React from 'react';
import styled from 'styled-components';
import { toNumberFormat } from '../features/common';

const StyledDiv = styled.div`
  justify-content: center;

  .name{
    color: var(--text-color);
  }
  
  .inventory{
    color: var(--text-color);
  }

  .outer-circle{
    padding: 8px;
    border-radius: 50%;
    height: 100px;
    width: 100px;
    
    .inner-circle{
      padding: 10px;
      height: 60px;
      width: 60px;
      border-radius: 50%;
      img{
        width: 100%;
      }
    }
  }
`

type Props = {
  src: string;
  name: string;
  inventory: number;
}
function Profile({ src, name, inventory }: Props) {
  return (
    <StyledDiv className='profile'>
      <div className='game-box outer-circle'>
        <div className='game-box inner-circle gold-bg'>
          <img src={src} alt={name} />
        </div>
      </div>
      <div className="name">
        {name}
      </div>
      <div className="inventory">
        {toNumberFormat(inventory)}
      </div>
    </StyledDiv>
  )
}

export default Profile