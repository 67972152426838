import { createSlice, SliceCaseReducers, SliceSelectors } from "@reduxjs/toolkit";
import { User } from "../types/User";

export const inviteSlice = createSlice<Array<User>, SliceCaseReducers<Array<User>>, string, SliceSelectors<Array<User>>, string>({
    name: 'invite',
    initialState: [],
    reducers: {
        setAll: (state, action) => {
            console.log({ inviteSlicePayload: action.payload });

            return [
                ...action.payload
            ]
        },
    }
});
export const { setAll } = inviteSlice.actions;
export default inviteSlice.reducer