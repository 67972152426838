import { configureStore } from "@reduxjs/toolkit";
import mainReducer from "./features/fullSlice";
import leagueSlice from "./features/leagueSlice";
import refTaskSlice from "./features/refTaskSlice";
import securitySlice from "./features/securitySlice";
import boosterSlice from "./features/boosterSlice";
import statisticSlice from "./features/statisticSlice";
import pageSlice from "./features/pageSlice";
import inviteSlice from "./features/inviteSlice";
import claimSlice from "./features/claimSlice";
import inventorySlice from "./features/inventorySlice";


const store = configureStore({
    reducer: {
        full: mainReducer,
        league: leagueSlice,
        refTask: refTaskSlice,
        security: securitySlice,
        boosters: boosterSlice,
        statistics: statisticSlice,
        page: pageSlice,
        invite: inviteSlice,
        claims: claimSlice,
        inventory: inventorySlice
    },
})


export default store;
export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
