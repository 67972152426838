import { createSlice } from "@reduxjs/toolkit";

export const boostersSlice = createSlice({
    name: 'boosters',
    initialState: {
        tappingLevels: [
            {
                id: 1,
                per_tap: 1,
                cost: 0,
                description: 'This is the basic level.',
                level: 1
            },
            {
                id: 2,
                per_tap: 2,
                cost: 10000,
                description: 'Increase amount of TAP you can earn per one tap.\n +1 per tap for each level',
                level: 2
            },
        ],
        storageLevels: [
            {
                id: 1,
                amount: 500,
                cost: 0,
                description: 'This is the basic level.',
                level: 1
            },
            {
                id: 2,
                amount: 1000,
                cost: 10000,
                description: 'Increase the limit of energy storage.\n +500 energy limit for each level.',
                level: 2
            },

        ],
        speedLevels: [
            {
                id: 1,
                per_second: 1,
                cost: 0,
                description: 'This is the basic level',
                level: 1
            },
            {
                id: 2,
                per_second: 2,
                cost: 50000,
                description: 'Increase speed of recharge.\n +1 per second.',
                level: 2
            },
        ],
        tappingBot: {
            is_active: false,
            cost: 200000,
            description: 'tapping for 12 hours when storage is full',
            started: new Date().toISOString(),
            expired: new Date().toISOString()
        }
    },
    reducers: {
        setAll: (state, action) => {
            return {
                ...state,
                levels: action.payload.levels,
            };
        },
        setTank: (state, action) => {
            return {
                ...state,
                tanks: [
                    action.payload.count,
                    3
                ]
            }
        },
        setGuru: (state, action) => {
            return {
                ...state,
                guru: [
                    action.payload.count,
                    3
                ]
            }
        },
        setTap: (state, action) => {
            return {
                ...state,
                tappingLevels: action.payload,
            }

        },
        setStorage: (state, action) => {
            return {
                ...state,
                storageLevels: action.payload,
            }

        },
        setSpeed: (state, action) => {
            return {
                ...state,
                speedLevels: action.payload
            }

        },
        setBot: (state, action) => {
            return {
                ...state,
                tappingBot: action.payload
            }

        },
    }
});
export const { setAll, setTank, setGuru, setTap, setStorage, setSpeed, setBot } = boostersSlice.actions;
export default boostersSlice.reducer