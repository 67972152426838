import { createSlice } from "@reduxjs/toolkit";

export const pageSlice = createSlice({
    name: 'page',
    initialState: {
        activePage: 'home',
    },
    reducers: {
        setActivePage: (state, action) => {
            return {
                ...state,
                activePage: action.payload,
            };
        }
    }
});
export const { setActivePage } = pageSlice.actions;
export default pageSlice.reducer