import { createSlice } from "@reduxjs/toolkit";

export const leagueSlice = createSlice({
    name: 'league',
    initialState: {
        levels: [
            { title: 'Wood League', reward: 0, count: 0, image: '/images/wood.png' },
            // { title: 'Bronze League', reward: 100, from: 1, image: '/images/bronze.png' },
            // { title: 'Silver League', reward: 500, from: 5000, image: '/images/silver.png' },
            // { title: 'Gold League', reward: 5000, from: 50000, image: '/images/gold.png' },
            // { title: 'Platinum League', reward: 25000, from: 250000, image: '/images/platinum.png' },
            // { title: 'Diamond League', reward: 50000, from: 500000, image: '/images/diamond.png' },
            // { title: 'Master League', reward: 100000, from: 1000000, image: '/images/master.png' },
            // { title: 'Grandmaster League', reward: 250000, from: 2500000, image: '/images/grandmaster.png' },
            // { title: 'Elite League', reward: 500000, from: 5000000, image: '/images/elite.png' },
            // { title: 'Legendary League', reward: 1000000, from: 10000000, image: '/images/legendary.png' },
            // { title: 'Mythic League', reward: 5000000, from: 50000000, image: '/images/mythic.png' },
        ]//.sort((a,b)=>b.from - a.from),
    },
    reducers: {
        setLeagues: (state, action) => {
            return {
                levels: action.payload,
            };
        }
    }
});
export const { setLeagues } = leagueSlice.actions;
export default leagueSlice.reducer