import { useState } from 'react';
import './App.css';
import { ThemeProvider, useTheme } from './providers/Theme';
import { Route, Routes, BrowserRouter } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from './hooks';
import Layout from './pages/Layout';
import useNetworkStatus from './hooks/UseNetworkStatus';
import Maintenance from './components/Maintenance';
import { HomePage } from './sections/HomePage';

function App() {
  const isOnline = useNetworkStatus();
  console.log({ isOnline });

  const { setTheme } = useTheme();
  const dispatch = useAppDispatch();
  const { loading } = useAppSelector(state => state.full);
  const [maintenanceMode, setMaintenanceMode] = useState(false);

  return (
    <div className="App">
      <ThemeProvider>
        {
          !isOnline ?
            <Layout>
              <div className='flex flex-v' style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
                <h3 className='gold-text' style={{ padding: 20 }}> Network Error. check your internet connection</h3>
                <p className='game-box gold-text' style={{ padding: 20 }}> Zizo Tap Team</p>
              </div>
            </Layout>
            :
            maintenanceMode ? <Maintenance /> :
              // loading ? <Loading /> :
              <Layout>
                <BrowserRouter>
                  <Routes>
                    <Route path='/' element={<HomePage />} />
                  </Routes>
                </BrowserRouter>
              </Layout>
        }
      </ThemeProvider>
      <div className="spare"></div>
    </div >
  );
}

export default App;
