import { createSlice, SliceCaseReducers, SliceSelectors } from "@reduxjs/toolkit";

export const inventorySlice = createSlice({
    name: 'inventory',
    initialState: {
        inventory: 0,
        remained: 0
    },
    reducers: {
        updateInventory: (state, action) => {
            return {
                inventory: state.inventory + action.payload,
                remained: state.remained - action.payload
            }
        },
        setInventory: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
    }
});
export const { updateInventory } = inventorySlice.actions;
export default inventorySlice.reducer