// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("../../public/images/bg_dark.png", import.meta.url);
var ___CSS_LOADER_URL_IMPORT_1___ = new URL("../../public/images/bg_light.png", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.style_wrapper__rg3iR {
    /* max-height: var(--tg-viewport-height, 100vh); */
    display: flex;
    flex-direction: column;
    background-color: #0e0e0e;
    align-items: center;
    justify-content: center;
}

.style_main__8sl0a {
    position: relative;
    background-color: var(--main-color);

    /* max-height: var(--tg-viewport-height); */
    /* width: 46.18vh; */
    /* max-width: 56.25vh; */

    width: 100%;
    /* background-image: url('http://localhost:3000/images/game-page2.png'); */
    /* background-image: url('http://localhost:3000/images/game-boost.png'); */
    /* background-image: url('http://localhost:3000/images/game-task.png'); */
    /* background-image: url('http://localhost:3000/images/tp-d.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    transition: background-color var(--transition-duration);
}

[data-theme='dark'] .style_main__8sl0a {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_0___});
}

[data-theme='light'] .style_main__8sl0a {
    background-image: url(${___CSS_LOADER_URL_REPLACEMENT_1___});
}`, "",{"version":3,"sources":["webpack://./src/pages/style.module.css"],"names":[],"mappings":"AAAA;IACI,kDAAkD;IAClD,aAAa;IACb,sBAAsB;IACtB,yBAAyB;IACzB,mBAAmB;IACnB,uBAAuB;AAC3B;;AAEA;IACI,kBAAkB;IAClB,mCAAmC;;IAEnC,2CAA2C;IAC3C,oBAAoB;IACpB,wBAAwB;;IAExB,WAAW;IACX,0EAA0E;IAC1E,0EAA0E;IAC1E,yEAAyE;IACzE,oEAAoE;IACpE,4BAA4B;IAC5B,sBAAsB;IACtB,2BAA2B;IAC3B,uDAAuD;AAC3D;;AAEA;IACI,yDAAwD;AAC5D;;AAEA;IACI,yDAAyD;AAC7D","sourcesContent":[".wrapper {\r\n    /* max-height: var(--tg-viewport-height, 100vh); */\r\n    display: flex;\r\n    flex-direction: column;\r\n    background-color: #0e0e0e;\r\n    align-items: center;\r\n    justify-content: center;\r\n}\r\n\r\n.main {\r\n    position: relative;\r\n    background-color: var(--main-color);\r\n\r\n    /* max-height: var(--tg-viewport-height); */\r\n    /* width: 46.18vh; */\r\n    /* max-width: 56.25vh; */\r\n\r\n    width: 100%;\r\n    /* background-image: url('http://localhost:3000/images/game-page2.png'); */\r\n    /* background-image: url('http://localhost:3000/images/game-boost.png'); */\r\n    /* background-image: url('http://localhost:3000/images/game-task.png'); */\r\n    /* background-image: url('http://localhost:3000/images/tp-d.png'); */\r\n    background-repeat: no-repeat;\r\n    background-size: cover;\r\n    background-position: center;\r\n    transition: background-color var(--transition-duration);\r\n}\r\n\r\n[data-theme='dark'] .main {\r\n    background-image: url('../../public/images/bg_dark.png');\r\n}\r\n\r\n[data-theme='light'] .main {\r\n    background-image: url('../../public/images/bg_light.png');\r\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"wrapper": `style_wrapper__rg3iR`,
	"main": `style_main__8sl0a`
};
export default ___CSS_LOADER_EXPORT___;
