import styled from 'styled-components';

const StyledGameUI = styled.div`
    overflow: hidden;
    display: flex;
    flex-direction: column;
    padding: 20px 250px;
    justify-content: flex-start;
    height: 100vh;

    @media (max-width: 1366px) {
        padding: 50px 120px;
    }
    @media (max-width: 1024px) {
        padding: 30px 10px;
        gap: 20px;
    }
    
    @media (max-width: 600px) {
        padding: 30px 10px;
        gap: 20px;
    }

    
    .menu{
        padding: 10px 30px;
        div{
            justify-content: space-between;
            align-items: center;
            .coin{
                img{
                    height: 40px;
                }
            }
            .icons{
                gap: 20px;

                img{
                    height: 30px;
                }
                button{
                    background: none;
                    border:none;
                    svg{
                        fill: var(--gold-dark);
                    }
                }
            }
        }
    
    }

    .title{
        padding: 10px 180px;
        @media (max-width: 600px) {
            padding: 10px 80px;
        }
        @media (max-width: 1024px) {
            padding: 10px 80px;
        }
        @media (max-width: 1440px) {
            padding: 10px 20px;
        }
        img{
            width: 100%;
        }

    }
    
    .content-wrapper{
        margin: 10px 180px;
        padding: 20px;
        border-radius: 40px;

        @media (max-width: 600px) {
            padding: 8px;
            margin:0;
        }
        
        @media (max-width: 1024px) {
            padding: 8px;
            margin:0;
        }
        @media (max-width: 1366px) {
            padding: 8px;
            margin:0;
        }
        

        .content{
            border-radius: 30px;
            padding: 30px 20px;
            font-size: 20px;
            color: var(--text-color);
            font-weight: bold;
            
            @media (max-width: 600px) {
                padding: 10px;
                font-size: 15px;
              }

            button{
                padding: 15px 100px;
                font-size: 20px;
                font-weight: bold;
                
                @media (max-width: 600px) {
                    padding: 15px;
                    font-size: 15px;
                  }
            }
        }
    }

    .users{
        gap: 10px;
        justify-content: center;
        padding: 0 120px;
        .profile:nth-child(4){
            transform: scale(1.2);
        }
        .profile:nth-child(1),
        .profile:nth-child(2),
        .profile:nth-child(6),
        .profile:nth-child(7),
        {
            transform: scale(0.8);
        }
    }
`

export { StyledGameUI }