import { GlobalStyle } from './GlobalStyle';
import { useTheme } from '../providers/Theme';
import style from './style.module.css';
import { BsInfoCircleFill, BsMoonFill, BsSunFill } from 'react-icons/bs';
import { Toaster } from 'react-hot-toast';
import { useEffect } from 'react';
import styled from 'styled-components';

const StyledCTA = styled.div`
    position:absolute;

    #cta-wrapper{
        height: 100vh;
        max-height: 100vh;
        width:100vw;
        max-width:100vw;
        position:relative;
        overflow: hidden;
        margin:0;
        padding: 0;
        z-index: 2;
        .circle{
            min-height: 20px;
            min-widht: 20px;
            position: absolute;
            border-radius: 50%;
            transform: scale(0.87);
            // opacity: 0.4;
        }
        .circle-1{
            top: 25px;
            left: -50px;
            height: 120px;
            width: 120px;
        }
        
        .circle-2{
            left: 50px;
            top: 210px;
            height: 180px;
            width: 180px;

        }

        .circle-3{
            left: -150px;
            top: 350px;
            height: 250px;
            width: 250px;
            .circle-4{
                height: 190px;
                width: 190px;
            }
        }
        
        .circle-5{
            left: -220px;
            top: 550px;
            height: 600px;
            width: 600px;
            .circle-6{
                height: 460px;
                width: 460px;
            }
        }
        
        .circle-7{
            right: -70px;
            top: 110px;
            height: 220px;
            width: 220px;

        }
    }
`

Layout.defaultProps = {
    hasFooter: true
}
function Layout({ hasFooter, children }: { hasFooter: boolean, children: any }) {
    const { theme, setTheme } = useTheme();
    const handleTheme = () => {
        let t = theme == 'dark' ? 'light' : 'dark';
        localStorage.setItem('theme', t);
        setTheme(t);
    }

    useEffect(() => {
        setTheme(localStorage.getItem('theme') ?? 'dark');
    }, [])

    return (
        <>
            <GlobalStyle data-theme={theme} $dark={theme === 'dark'} $shadow={theme == 'dark' ? { high: '#1f1f1f', low: '#0e0e0e' } : { high: '#ffffffb3', low: '#00000066' }} />
            {/* <StyledCTA>
                <div id="cta-wrapper">
                    <div className="circle circle-1 game-box"></div>
                    <div className="circle circle-2 game-box"></div>
                    <div className="circle circle-3 centered game-box">
                        <div className="circle circle-4 game-box"></div>
                    </div>
                    <div className="circle circle-5 centered game-box">
                        <div className="circle circle-6 game-box"></div>
                    </div>
                    <div className="circle circle-7 game-box"></div>
                </div>
            </StyledCTA> */}
            <div className={style.wrapper} data-theme={theme}>
                <div className={style.main + (!hasFooter ? ' main-no-footer' : '')}>
                    {/* <button className="theme-changer game-box clickable" onClick={handleTheme}>
                        {theme === 'dark' ? <BsSunFill size={16} /> : <BsMoonFill size={16} />}
                    </button> */}
                    {children}
                </div>
                <Toaster position={'bottom-center'} />
            </div>
        </>
    )
}

export default Layout
