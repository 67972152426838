import { createSlice } from "@reduxjs/toolkit";

export const fullSlice = createSlice({
    name: 'full',
    initialState: {
        loading: true,
        user_id: null,
        storage: 500,
        energy: 0,
        total_inventory: 0,
        inventory: 0,
        prev_inventory: 0,
        per_tap: 1,
        speed: 1,
        tank: [0, 3],
        guru: [0, 3],
        coinLevel: 1,
        energyLevel: 1,
        speedLevel: 1,
        referral_code: 'r_',
        robotSecondsRemain: 0,
        robotProfit: 0,
        guru_mode: false,
        invited_count: 0,
        daily_counter: 0,
    },
    reducers: {
        setUserData: (state, action) => {
            console.log(action.payload);

            return {
                ...state,
                user_id: action.payload.chat_id,
                storage: action.payload.storage,
                inventory: action.payload.inventory,
                total_inventory: action.payload.total_inventory,
                referral_code: action.payload.referral_code,
                per_tap: action.payload.per_tap,
                speed: action.payload.speed
            };
        },
        setAll: (state, action) => {
            return {
                ...state,
                ...action.payload
            }
        },
        setInitialData: (state, action) => {
            return {
                ...state,
            }
        }
    }
});
export const { setInitialData, setAll } = fullSlice.actions;
export default fullSlice.reducer