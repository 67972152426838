import { StyledGameUI } from './styled.page'
import { useTheme } from '../../providers/Theme';
import { BsMoonFill, BsSunFill } from 'react-icons/bs';
import coin from '../../assets/images/zizo-coin-64.png';
import telegram from '../../assets/images/telegram.png';
import twitter from '../../assets/images/X.png';
import Profile from '../../components/Profile';
import { useEffect, useState } from 'react';

type User = {
    username: string;
    inventory: number;
    total_inventory: number;
}

type SortabledUser = User & {
    order: number;
}
type Response = {
    status: string;
    data: User[];
}
const orders = [3, 4, 2, 5, 1, 6, 0];
function Index() {

    const { theme, setTheme } = useTheme();
    const [players, setPlayers] = useState<SortabledUser[]>([]);
    useEffect(() => {
        fetch('https://gamebackend.zizo.club/api/statistical/tenTopUsers?type=total_inventory')
            .then(response => response.json()).then((response: Response) => {
                if (response.status === 'success') {
                    let users: SortabledUser[] = response.data.sort((a, b) => b.total_inventory - a.total_inventory).slice(0, 7).map((i, k) => ({ ...i, order: orders[k] }));
                    setPlayers(users);
                }
            });
    }, [])

    console.log({ players });

    let diff = 1713969967;
    let max = Math.round((new Date().valueOf() / 1000 - diff) / 1000) * 1000;
    return (
        <StyledGameUI className='main-section'>
            <div className="menu box">
                <div className="flex flex-h">
                    <div className="coin">
                        <img src={coin} />
                    </div>
                    <div className="icons flex flex-h">
                        <a href="https://t.me/zizotap">
                            <img src={telegram} alt="telegram" />
                        </a>
                        <a href="https://x.com/zizotap">
                            <img src={twitter} alt="twitter" />
                        </a>
                        {/* <button className="theme-button" onClick={() => { setTheme(theme === 'dark' ? 'light' : 'dark') }}>
                            {theme === 'dark' ? <BsSunFill size={18} /> : <BsMoonFill size={18} />}
                        </button> */}
                    </div>
                </div>
            </div>
            <div className="title">
                <img src={theme == 'dark' ? '/images/title_dark.png' : '/images/title_light.png'} alt="Blast off with ZIZO" />
            </div>
            <div className="content-wrapper game-box-v2">

                <div className="content game-box-v2">
                    <p>
                        Are you ready to transform your taps into financial gains? Join us in  the app, where every tap contributes to
                        profit-sharing and endless  excitement! Let's tap into success together!
                    </p>
                    <a href="https://t.me/zizotapbot">
                        <button className='game-box gold-text' >Join Us</button>
                    </a>
                </div>
            </div>
            <div className="users flex flex-h">
                {/* <img src={theme === 'dark' ? '/images/users-dark.png' : '/images/users-light.png'} /> */}
                {
                    // [{ username: 'Ethan Blake', total_inventory: max - 590654 }, { username: 'Olivia Harper', total_inventory: max - 123854 }, { username: 'Lucas Bennett', total_inventory: max - 18954 }, { username: 'Ava Mitchell', total_inventory: max - 0 }, { username: 'Noah Cooper', total_inventory: max - 80564 }, { username: 'Sophia Hayes', total_inventory: max - 269045 }, { username: 'Liam Foster', total_inventory: max - 810654 },]
                    players.sort((a, b) => a.order - b.order)
                        .map(i => {
                            return <Profile src={coin} name={i.username ? i.username : 'NoName'} inventory={i.total_inventory} />
                        })}
            </div>
        </StyledGameUI>
    )
}

export default Index