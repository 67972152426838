import React, { useEffect, useState } from 'react'
import { BsTools } from 'react-icons/bs';
import Layout from '../pages/Layout';

function Maintenance() {
    const [remained, setRemained] = useState<number>(300);
    useEffect(() => {
        const interval = setInterval(() => {
            if (remained > 0)
                setRemained(r => r - 1);
            else {
                window?.location?.reload();
            }
        }, 1000)

        return () => {
            clearInterval(interval);
        }
    }, [])



    return <Layout hasFooter={false}>
        <div className='flex flex-v' style={{ height: '100vh', alignItems: 'center', justifyContent: 'center' }}>
            <div><BsTools size={30} color='#777' /></div>
            <h3 className='gold-text darker' style={{ padding: 20 }}> We are facing some issues and we are under maintenace right now. we are trying to fix it as soon as possible. please try again in 5 minutes</h3>
            <p className='gold-text darker'>Automatic reload in {remained} seconds</p>
            <p className='game-box gold-text darker' style={{ padding: 20 }}> Zizo Tap Team</p>
        </div>
    </Layout>
}

export default Maintenance